<template>
  <v-container
      fluid
      tag="section"
  >
    <v-card
        icon="mdi-clipboard-text"
        title="Simple Table"
        class="px-5 py-3"
    >

      <v-toolbar flat>
        <v-autocomplete
            label="Shipment status"
            class="mr-5"
            multiple
            item-text="title"
            item-value="value"
            :items="shipmentStatuses"
            v-model="filters.status__in"
            clearable
            :name="Math.random()"
            :autocomplete="Math.random()"
        />
        <v-menu
            v-model="dateFromIsOpen"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="filters.ship_date__gte"
                label="Date from"
                class="mr-5"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                clearable
                :name="Math.random()"
                :autocomplete="Math.random()"
            ></v-text-field>
          </template>
          <v-date-picker
              v-model="filters.ship_date__gte"
              @input="dateFromIsOpen = false"
          ></v-date-picker>
        </v-menu>
        <v-menu
            v-model="dateToIsOpen"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            class="mr-5"
            offset-y
            min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="filters.ship_date__lte"
                label="Date to"
                class="mr-5"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                clearable
                :name="Math.random()"
                :autocomplete="Math.random()"
            ></v-text-field>
          </template>
          <v-date-picker
              v-model="filters.ship_date__lte"
              @input="dateToIsOpen = false"
          ></v-date-picker>
        </v-menu>
        <v-autocomplete
            label="Country from"
            class="mr-5"
            item-text="title"
            item-value="code"
            :items="countries"
            v-model="filters.shipment__ship_from__country"
            clearable
            :name="Math.random()"
            :autocomplete="Math.random()"
        />
        <v-autocomplete
            label="Country to"
            item-text="title"
            item-value="code"
            :items="countries"
            v-model="filters.shipment__ship_to__country"
            clearable
            :name="Math.random()"
            :autocomplete="Math.random()"
        />
        <v-spacer/>
        <v-text-field label="Search" prepend-icon="mdi-magnify" style="flex-grow: 6" v-model="filters.search" />
      </v-toolbar>

      <v-data-table
        :headers="headers"
        :items="items"
        :page.sync="filters.page"
        :items-per-page="items.length"
        hide-default-footer
        :loading="loading"
        @click:row="handleClick"
        class="table"
      >
        <template v-slot:item.ship_date="{item}">
          <span>{{ item.ship_date | date('LL') }}</span>
        </template>
        <template v-slot:item.status="{item}">
          <v-chip :color="getStatusColor(item.status)">{{ getStatusTitle(item.status)}}</v-chip>
        </template>
        <template v-slot:item.receiver="{item}">
          <span>{{ `${item.shipment.ship_to.contact_name} ${item.shipment.ship_to.postal_code}, ${[item.shipment.ship_to.street1, item.shipment.ship_to.street2, item.shipment.ship_to.street3].filter(e => !!e).join(' ')}, ${item.shipment.ship_to.city}, ${item.shipment.ship_to.country}` }}</span>
        </template>
      </v-data-table>
      <template v-slot:item.actions="{item}">
        <v-btn outlined @click="console.log(item)" color="primary" class="mr-3" small>Docs</v-btn>
        <v-btn outlined @click="console.log(item)" class="mr-3" small>View</v-btn>
        <v-btn outlined @click="console.log(item)" color="warning" class="mr-3" small>Track</v-btn>
        <v-btn outlined @click="console.log(item)" color="success" class="mr-3" small>Repeat</v-btn>
      </template>

      <v-pagination
          v-model="filters.page"
          :length="pageCount"
      ></v-pagination>
    </v-card>
  </v-container>
</template>

<script>
import {orderStatuses, shipmentStatuses} from '@/choices';
import Country from "@/models/Country";
import Order from "@/models/Order";

export default {
  data() {
    return {
      shipmentStatuses,
      pageCount: 1,
      dateFrom: '',
      dateTo: '',
      dateFromIsOpen: false,
      dateToIsOpen: false,
      loading: true,
      filters: {
        page: 1
      },
      expand: [
        'shipment',
        'shipment.parcels',
        'shipment.parcels.items',
        'shipment.ship_to',
        'shipment.ship_from',
        'selected_rate',
        'selected_rate.shipper_account'
      ],
      headers: [
        {
          text: 'Date',
          value: 'ship_date'
        },
        {
          text: 'Reference',
          value: 'reference'
        },
        {
          text: 'Status',
          value: 'status'
        },
        {
          text: 'Receiver',
          value: 'receiver'
        },
        {
          text: 'Tracking number',
          value: 'tracking_number'
        },
        {
          text: 'Pickup reference',
          value: 'pickup_reference'
        },
        {
          text: 'Carrier',
          value: 'selected_rate.shipper_account.title'
        },
        {
          text: 'Service',
          value: 'selected_rate.service_name'
        },
        {
          value: 'actions',
          align: 'right'
        }
      ],
      items_order: [],
    }
  },
  computed: {
    countries() {
      return Country.all();
    },
    items() {
      return this.items_order.map(item_id => Order.find(item_id))
    }
  },
  methods: {
    handleClick(item) {
      this.$router.push({name: 'Shipment', params: {id: item.id}})
    },
    transformFilters(filters) {
      return Object.entries(filters).reduce((acc, [key, val])=> {
        acc[key] = Array.isArray(val) ? val.join(',') : val;
        return acc
      }, {});
    },
    getStatusColor(status) {
      return orderStatuses.find(choice => choice.value === status).color
    },
    getStatusTitle(status) {
      return orderStatuses.find(choice => choice.value === status).title
    },
  },
  watch: {
    filters: {
      async handler(value) {
        const withExpandValue = {...value, expand: this.expand};
        const transformedFilters = this.transformFilters(withExpandValue);
        await Order.deleteAll();
        this.loading = true;
        const ordersResponse = await Order.api().get('/api/v1/shipping/orders/', {params: transformedFilters});
        this.pageCount = ordersResponse.response.data.total_pages;
        this.items_order = ordersResponse.response.data.results.map(item => item.id)
        this.loading = false;
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style scoped>
  .table {
    cursor: pointer;
  }
</style>
